function createObject() {
  let example = {
    id: "Kernaufgaben",
    workOrder:
      "Leiten Sie diese Aufgabe des Einmaleins mit Hilfe einer Kernaufgabe ab. Erläutern und veranschaulichen Sie, wie Sie dies gemacht haben.",
    originalSlideWidth: 750,
    originalSlideHeight: 400,
    slides: [
      {
        image: require("@/assets/eme/Schieberegler/Kernaufgaben/step0_Kernaufgaben.png"),
      },
      {
        animation: require("@/assets/eme/Schieberegler/Kernaufgaben/step1_Kernaufgaben_animation.webm"),
        animationAlt: require("@/assets/eme/Schieberegler/Kernaufgaben/step1_Kernaufgaben_animation.mp4"),
        intervention: true,
        interventionText:
          "Welche der Aufgaben ist eine Kernaufgabe und kann ihnen beim Lösen der Aufgabe 3 · 8 helfen?",
        interventionOptions: [
          {
            title: "A",
            correct: false,
            hintText:
              "3 · 7 liegt zwar direkt neben 3 · 8 und könnte, wenn einem Kind die Lösung hierzu bekannt ist helfen. Allerdings ist 3 · 7  keine Kernaufgabe. Überlegen Sie, welche Aufgaben direkt neben 3 · 8 liegen und zudem Kernaufgaben sind.",
            top: 65,
            left: 410,
            width: 130,
            height: 70,
          },
          {
            title: "B",
            correct: false,
            hintText:
              "8 · 3 stellt die Tauschaufgabe von 3 · 8 dar und kann manchen Kinder helfen, wenn das Ergebnis dieser bekannt ist. Allerdings ist dieses Vorgehen keine Ableitungsstrategie. Überlegen Sie, wie aus einer Kernaufgabe die Lösung zur Aufgabe 3 · 8 abgeleitet werden kann.",
            top: 150,
            left: 410,
            width: 130,
            height: 70,
          },
          {
            title: "C",
            correct: true,
            hintText: "",
            top: 230,
            left: 410,
            width: 130,
            height: 70,
          },
        ],
      },
      {
        animation: require("@/assets/eme/Schieberegler/Kernaufgaben/step2_Kernaufgaben_animation.webm"),
        animationAlt: require("@/assets/eme/Schieberegler/Kernaufgaben/step2_Kernaufgaben_animation.mp4"),
        successText:
          "Prima! 2 · 8 ist eine Verdopplungsaufgabe und gehört damit zu den Kernaufgaben des Einmaleins. Zudem liegt diese Aufgabe direkt neben 3 · 8 und ist somit hilfreich, um eine Lösung zu finden. Hier muss zum Produkt nur noch 1 · 8 addiert werden, um die Aufgabe 3 · 8 mit Hilfe von 2 · 8 zu lösen.",
      },
      {
        animation: require("@/assets/eme/Schieberegler/Kernaufgaben/step3_Kernaufgaben_animation.webm"),
        animationAlt: require("@/assets/eme/Schieberegler/Kernaufgaben/step3_Kernaufgaben_animation.mp4"),
        intervention: true,
        interventionText:
          "Wie kann man diese Darstellung der Aufgabe 3 · 8 sprachlich beschreiben? Die Beschreibung sollte den Vorstellungsaufbau fördern.",
        interventionOptions: [
          {
            title: "A",
            correct: false,
            hintText:
              "Diese Formulierung hilft nicht beim Aufbau vom Verständnis der Multiplikationsaufgabe. Sie gibt zwar die vorgegebene Aufgabe sprachlich wieder, allerdings wird die Vorstellung von der Anzahl der Gruppen dadurch nicht unterstützt. Überlegen Sie noch einmal, welche Formulierung zu der Plättchendarstellung von 3 · 8 passt. ",
            top: 65,
            left: 410,
            width: 130,
            height: 70,
          },
          {
            title: "B",
            correct: true,
            hintText: "",
            top: 150,
            left: 410,
            width: 130,
            height: 70,
          },
          {
            title: "C",
            correct: false,
            hintText:
              "Leider passt diese Formulierung konventionell nicht zu der Plättchendarstellung. Sie würde nahelegen, dass acht Reihen mit jeweils drei Plättchen dargestellt wurden. Überlegen Sie noch einmal, welche Formulierung zu dieser Plättchendarstellung von 3 · 8 passt.",
            top: 230,
            left: 410,
            width: 130,
            height: 70,
          },
        ],
      },
      {
        animation: require("@/assets/eme/Schieberegler/Kernaufgaben/step4_Kernaufgaben_animation.webm"),
        animationAlt: require("@/assets/eme/Schieberegler/Kernaufgaben/step4_Kernaufgaben_animation.mp4"),
        successText:
          "Prima! Diese Formulierung finden wir auch sehr passend für diese Darstellung zur Aufgabe 3 · 8. Es wird deutlich, dass hier drei Reihen mit jeweils acht Plättchen zu sehen sind.",
      },
      {
        animation: require("@/assets/eme/Schieberegler/Kernaufgaben/step5_Kernaufgaben_animation.webm"),
        animationAlt: require("@/assets/eme/Schieberegler/Kernaufgaben/step5_Kernaufgaben_animation.mp4"),
        intervention: true,
        interventionText:
          "Die vorherige Animation zeigt, wie aus der Darstellung 2 · 8 die Darstellung von 3 · 8 wird. Wie können Sie sprachlich beschreiben, was zu der Darstellung von 2 · 8 hinzugefügt wird, um zu der Darstellung von 3 · 8 zu kommen?",
        interventionOptions: [
          {
            title: "A",
            correct: true,
            hintText: "",
            top: 65,
            left: 410,
            width: 130,
            height: 70,
          },
          {
            title: "B",
            correct: false,
            hintText:
              "Leider passt diese Formulierung nicht zu der Veränderung der Darstellung. Sie würde in der oberen Darstellung zu 2 · 8 nahelegen, dass an der rechten Seite jeweils ein Plättchen an jeder Reihe hinzugefügt wird. Überlegen Sie noch einmal, wie Sie die konkrete Veränderung in der Darstellung beschreiben können.",
            top: 150,
            left: 410,
            width: 130,
            height: 70,
          },
          {
            title: "C",
            correct: false,
            hintText:
              "Diese Formulierung halten wir für eher unpassend. Immer ein Plättchen unter jede Spalte hinzuzufügen würde zwar zu der korrekten Darstellung führen, allerdings wird eine komplette Achtergruppe hinzugefügt und nicht die Mächtigkeit der Gruppen jeweils um eins erhöht. Überlegen Sie noch einmal, wie Sie die konkrete Veränderung in der Darstellung beschreiben können.",
            top: 230,
            left: 410,
            width: 130,
            height: 70,
          },
        ],
      },
      {
        animation: require("@/assets/eme/Schieberegler/Kernaufgaben/step6_Kernaufgaben_animation.webm"),
        animationAlt: require("@/assets/eme/Schieberegler/Kernaufgaben/step6_Kernaufgaben_animation.mp4"),
        successText:
          "Sehr gut! Diese Formulierung finden wir auch sehr treffend, um zu beschreiben, was in der Darstellung hinzugefügt werden muss. Es wird deutlich, dass eine Reihe mit acht Plättchen hinzugefügt wird. ",
      },
      {
        animation: require("@/assets/eme/Schieberegler/Kernaufgaben/step7_Kernaufgaben_animation.webm"),
        animationAlt: require("@/assets/eme/Schieberegler/Kernaufgaben/step7_Kernaufgaben_animation.mp4"),
        intervention: true,
        interventionText:
          "Die Darstellung der Aufgabe 3 · 8 wurde nun um 90 Grad gedreht, somit stellt sie die Aufgabe 8 · 3 dar. Wie kann man nun diese Darstellung der Aufgabe 8 · 3 sprachlich beschreiben? Auch hier sollte die Beschreibung wieder den Vorstellungsaufbau fördern.",
        interventionOptions: [
          {
            title: "A",
            correct: false,
            hintText:
              "Leider passt diese Formulierung nicht zu der Plättchendarstellung. Sie würde nahelegen, dass drei Reihen mit jeweils acht Plättchen dargestellt wurden. Überlegen Sie noch einmal, welche Formulierung zu dieser Plättchendarstellung passt.",
            top: 65,
            left: 410,
            width: 130,
            height: 70,
          },
          {
            title: "B",
            correct: false,
            hintText:
              "Diese Formulierung passt leider nicht zur Darstellung von 8 · 3. Sie gibt zwar die vorgegebene Aufgabe wieder, allerdings sagt sie nichts über die Darstellung der Aufgabe aus. Überlegen Sie, welche Formulierung zu der Plättchendarstellung passt. ",
            top: 150,
            left: 410,
            width: 130,
            height: 70,
          },
          {
            title: "C",
            correct: true,
            hintText:
              "Prima! Diese Formulierung finden wir auch sehr passend für diese Darstellung zur Aufgabe 8 · 3. Es wird deutlich, dass hier acht Reihen mit jeweils drei Plättchen zu sehen sind. ",
            top: 230,
            left: 410,
            width: 130,
            height: 70,
          },
        ],
      },
      {
        animation: require("@/assets/eme/Schieberegler/Kernaufgaben/step8_Kernaufgaben_animation.webm"),
        animationAlt: require("@/assets/eme/Schieberegler/Kernaufgaben/step8_Kernaufgaben_animation.mp4"),
        successText:
          "Prima! Diese Formulierung finden wir auch sehr passend für diese Darstellung zur Aufgabe 8 · 3. Es wird deutlich, dass hier acht Reihen mit jeweils drei Plättchen zu sehen sind. ",
      },
    ],
  };
  return example;
}

export default createObject();
