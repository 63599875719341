function createObject() {
  let example = {
    id: "LBST_Stempeln_16x4",
    workOrder:
      "Mia beschreibt im folgenden Audio ihren Rechenweg zu den Aufgaben 8·7 und 8·3.",
    audio: require("@/assets/eme/mia_8mal7.mp4"),
    elements: [
      [
        {
          question: "Wie geht Mia vermutlich gedanklich bei 6·9 vor?",
          answers: [
            {
              correct: false,
              text: "9⋅6 = 9⋅(2⋅3) = (9⋅2)⋅3 = 18⋅3 ",
              response:
                "Hören Sie sich Mias Beschreibung nochmal an. Sie nutzt eine additive Zerlegungsstrategie.",
            },
            {
              correct: false,
              text: "9⋅6 = (5+4)⋅(5+1) = 5⋅5 + 4⋅1",
              response:
                "Hören Sie sich Mias Beschreibung nochmal an. Sie nutzt zwar eine additive Zerlegungsstrategie, doch löst sie die andere Aufgabe korrekt.",
            },
            {
              correct: true,
              text: "9⋅6 = 9 ⋅(5+1) = 9⋅5 + 9⋅1",
              response: "Sehr gut, genau so hat Mia die Aufgabe gelöst.",
            },
          ],
        },
      ],
      [
        {
          question:
            "Von welchen Kernaufgaben könnte Mia hier Gebrauch gemacht haben?",
          answers: [
            {
              correct: false,
              text: "Malaufgaben mit 10",
              response:
                "Hören Sie sich nochmal an, wie Mia vorgeht. Zur Erinnerung Malaufgaben mit 10 sind zum Beispiel: 6⋅10 = 60; 9⋅10 = 90.",
            },
            {
              correct: false,
              text: "Quadratzahlen ",
              response:
                "Hören Sie sich nochmal an, wie Mia vorgeht. Zur Erinnerung Quadratzahlen sind zum Beispiel: 2⋅2 = 4; 5⋅5 = 25",
            },
            {
              correct: true,
              text: "5-mal Aufgaben und 1-mal Aufgaben",
              response:
                "Genau, Mia nutzt hier vermutlich ihr gefestigtes Vorwissen zu den 5-mal und 1-mal Aufgaben, die zu den Kernaufgaben im kleinen Einmaleins gehören.",
            },
            {
              correct: false,
              text: "Verdopplungsaufgaben und 1-mal Aufgaben",
              response:
                "Hören Sie sich nochmal an, wie Mia vorgeht. Zur Erinnerung Verdopplungsaufgaben sind zum Beispiel: 2⋅3 = 6; 2⋅8 = 16 und 1-mal Aufgaben sind beispielsweise: 1⋅2 = 2; 1⋅7=7.",
            },
          ],
        },
        {
          question: "Welche Ableitungsstrategie wendet Mia bei dieser Aufgabe an?",
          answers: [
            {
              correct: false,
              text: "Nachbaraufgaben (Multiplikator bleibt gleich)",
              response: "Hören Sie sich nochmal an, wie Mia vorgeht. Zur Erinnerung bei Nachbaraufgaben, wo der Multiplikator gleich bleibt, werden Nachbaraufgaben aus der um 1 erhöhten oder verminderten Reihe zur Ableitung genommen. Beispiel: 9⋅6 sind 9 Einer mehr, als 9⋅5. Aus 9 Fünfer werden 9 Sechser. ",
            },
            {
              correct: false,
              text: "Tauschaufgabe ",
              response: "Hören Sie sich nochmal an, wie Mia vorgeht. Zur Erinnerung bei Tauschaufgaben werden Multiplikator und Multiplikand getauscht, da manchmal die Tauschaufgabe einfach zu lösen ist oder eine Ableitung erleichtert. Beispiel: 5⋅3 wird zu 3⋅5.",
            },
            {
              correct: false,
              text: "Gegensinniges Verändern",
              response: "Hören Sie sich nochmal an, wie Mia vorgeht. Zur Erinnerung beim Gegensinnigen Verändern wird der eine Faktor einer Multiplikationsaufgabe zum Beispiel halbiert oder gedrittelt und der andere entsprechend verdoppelt oder verdreifacht. Beispiel: 4⋅6 ist das Gleiche wie 2⋅12.",
            },
            {
              correct: true,
              text: "Zerlegen eines Faktors ",
              response: "Genau, Mia wendet hier die Ableitungsstrategie „Zerlegen eines Faktors“ an, indem sie den Multiplikanden 6 in 5 und 1 zerlegt.",
            },
          ],
        },
        {
          question: "Auf welchem Rechengesetz beruht die Ableitungsstrategie, die Mia hier nutzt?",
          answers: [
            {
              correct: false,
              text: "Kommutativgesetz",
              response: "Hören Sie sich nochmal an, wie Mia vorgeht. Bei welchem Gesetz ist die additive Zerlegung eines Faktors zentral? Zur Erinnerung das Kommutativgesetz: a+b = b+a.",
            },
            {
              correct: true,
              text: "Distributivgesetz ",
              response: "Genau, Mia nutzt hier das Distributivgesetz: a∙b + a∙c = a · (b+c).",
            },
            {
              correct: false,
              text: "Assoziativgesetz",
              response: "Hören Sie sich nochmal an, wie Mia vorgeht. Bei welchem Gesetz ist die additive Zerlegung eines Faktors zentral? Zur Erinnerung das Assoziativgesetz: (a+b)+c= a+(b+c).",
            },
            {
              correct: false,
              text: "Konstanzgesetz",
              response: "Hören Sie sich nochmal an, wie Mia vorgeht. Bei welchem Gesetz ist die additive Zerlegung eines Faktors zentral? Zur Erinnerung das Konstanzgesetz der Summe: a+b = (a±1)+(b±1).",
            },
          ],
        },
      ],
    ],
  };
  return example;
}

export default createObject();
