<template>
  <div>
    <h2 class="faledia-caption">Ableitungsstrategien</h2>
    <v-card outlined>
      <v-card-title>Was sollen die Schüler:innen lernen?</v-card-title>
      <v-card-text>
        Die Kinder können...
        <ul>
          <li>
            erkennen, welche Aufgaben ihnen beim Lösen einer schwierigeren
            Aufgabe helfen können,
          </li>
          <li>
            geeignete Ableitungsstrategien (mit Hilfe der Einmaleinstafel)
            finden und
          </li>
          <li>
            Ableitungsstrategien anwenden, um schwierigere
            Multiplikationsaufgaben zu lösen.
          </li>
        </ul>
      </v-card-text>
    </v-card>
    <br />
    <p>
      Um flexibel und sicher multiplizieren zu können, müssen die Lernenden
      Beziehungen und Strukturen zwischen einfacheren und schwierigeren Aufgaben
      erkennen, um sie dann zur Lösung der schwierigeren Aufgaben nutzen zu
      können. Aus diesem Grund sollten die Lernenden lernen, wie sich weitere
      Aufgaben aus den Kernaufgaben ableiten lassen, sobald sie die Kernaufgaben
      sicher aus dem Gedächtnis abrufen können (Gaidoschik, 2022).
    </p>
    <p>
      Nachfolgend wird Ihnen die Gelegenheit geboten, zunächst selbst
      reflektiert zu überlegen, welche Kernaufgaben sich anbieten, um
      schwierigere Aufgaben zu lösen.
    </p>
    <div style="background-color: white">
      <LernbausteinSchieberegler
        :id="Schieberegler.id"
        :workOrder="Schieberegler.workOrder"
        :originalSlideWidth="Schieberegler.originalSlideWidth"
        :originalSlideHeight="Schieberegler.originalSlideHeight"
        :slides="Schieberegler.slides"
        ><template v-slot:alternative-content>
          <p>
            Kinder müssen zunächst lernen, dass einfache Aufgaben ihnen beim
            Lösen schwierigerer Aufgaben helfen können. Dazu ist es notwendig,
            dass sie Beziehungen zwischen Aufgaben wahrnehmen. Sich geistig
            Operationen vorstellen zu können, ist eine Voraussetzung, um
            Ableitungsstrategien verständig aufbauen zu können.
            Bedeutungsbezogene Sprache kann den Aufbau dieser Vorstellungen
            unterstützen. Wenn wir hier zum Beispiel anstatt von „dreimal 8" von
            „drei Achtern“ sprechen, kann dies dazu beitragen, dass das Kind
            sich die Aufgabe geistig vorstellen kann. Kann das Kind die
            Kernaufgabe 2 · 8 = 16 bereits automatisiert abrufen, kann es sich
            das Ergebnis von 3 · 8 ableiten, indem es weiß, dass dafür „zu zwei
            Achtern ein weiterer Achter hinzukommen“ muss.
          </p>
        </template>
      </LernbausteinSchieberegler>
    </div>
    <p>
      Die folgende Tabelle bietet einen Überblick über typische
      Ableitungsstrategien beim Einmaleins (vgl. Padberg & Benz, 2021 und
      Gaidoschik, 2022).
    </p>
    <p><strong>Typische Ableitungsstrategien:</strong></p>
    <v-simple-table>
      <template v-slot:default>
        <tbody>
          <tr>
            <td>
              <strong>Nachbaraufgaben (Multiplikand bleibt gleich)</strong>
            </td>
            <td>
              Nachbaraufgaben aus der um 1 erhöhten oder verminderten Reihe
              werden zur Ableitung genommen (additiv oder auch subtraktiv).
            </td>
            <td class="no-wrap">
              6 · 7 sind 7 mehr als 5 · 7
              <br />
              4 · 7 sind 7 mehr als 3 · 7
              <br />
              4 · 7 sind 7 weniger als 5 · 7
            </td>
          </tr>
          <tr>
            <td>
              <strong>Nachbaraufgaben (Multiplikator bleibt gleich)</strong>
            </td>
            <td>
              Nachbaraufgaben aus der jeweiligen Reihe werden zur Ableitung
              genommen (additiv oder auch subtraktiv).
            </td>
            <td>
              7 · 6 sind 7 Einer mehr als 7 · 5 aus 7 Fünfern werden 7 Sechser
            </td>
          </tr>
          <tr>
            <td><strong>Zerlegen eines Faktors</strong></td>
            <td>
              Hierbei werden die Faktoren so zerlegt, dass einfache Aufgaben
              genutzt werden können (Nachbaraufgaben sind Spezialfälle dieser
              Strategie).
            </td>
            <td>7 · 8 rechne ich über 5 · 8 plus 2 · 8</td>
          </tr>
          <tr>
            <td><strong>Tauschaufgabe</strong></td>
            <td>
              Manchmal sind Tauschaufgaben einfacher zu lösen oder können
              einfacher zur Ableitung genutzt werden.
            </td>
            <td>Bei 7 · 2 rechne ich lieber 2 · 7.</td>
          </tr>
          <tr>
            <td><strong>Verdoppeln/Halbieren eines Faktors</strong></td>
            <td>
              Beim Verdoppeln/Halbieren eines Faktors werden kleine Aufgaben
              ausgenutzt, um doppelt so große zu berechnen.
            </td>
            <td>Bei 4 · 9 rechne ich 2 · 9 und dann nochmal 2 · 9.</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <br />
    <p>
      Es ist nicht zentral, dass alle Kinder alle Strategien gleichermaßen gut
      beherrschen, es geht vielmehr darum, den Kindern zu ermöglichen, sicher
      und flexibel – auch unter Einbezug ihrer Präferenzen – schwieriger
      scheinende Aufgaben durch das Ausnutzen von Zusammenhängen zu lösen.
    </p>
    <p>
      Nachdem Sie nachvollziehen konnten, wie Kernaufgaben dazu genutzt werden
      können, um schwierige Multiplikationsaufgaben zu lösen und Sie
      Ableitungsstrategien kennengelernt haben, können Sie Ihre Überlegungen nun
      anhand der folgenden Aufgabe vertiefen. Diese zeigt Ihnen anhand von
      Audio-Files, wie die Schülerin Mia auf verschiedene Weisen
      Multiplikationsaufgaben unter zu Hilfenahme von Kernaufgaben löst.
      Versuchen Sie nachzuvollziehen, wie Mia vorgeht, welche
      Ableitungsstrategien Mia nutzt und welche Rechengesetze hinter der
      Strategie von Mia stecken.
    </p>
    <div style="background-color: white">
      <LernbausteinStempeln
        :id="LBSTStempeln[0].id"
        :workOrder="LBSTStempeln[0].workOrder"
        :image="LBSTStempeln[0].image"
        :audio="LBSTStempeln[0].audio"
        :elements="LBSTStempeln[0].elements"
      >
        <template v-slot:alternative-content-0>
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr>
                  <td>16⋅4 = 8⋅8</td>
                  <td>Gegensinniges Verändern</td>
                  <td>Konstanzgesetz</td>
                </tr>
              </tbody></template
            ></v-simple-table
          >
          <p>
            Mia nutzt bei der Aufgabe 12·5 die Rechenstrategie des gegensinnigen
            Veränderns, indem sie den Multiplikator halbiert und den
            Multiplikanden entsprechend verdoppelt. Aus diesem Grund kann auch
            vermutet werden, dass sie die Aufgabe 16⋅4 auf dem gleichen Weg
            löst. Somit folgt aus der Aufgabe 16⋅4 die Kernaufgabe 8 · 8. Die
            hier verwendete Strategie basiert auf dem Konstanzgesetz: a·b =
            (a:2)·(b·2).
          </p>
        </template>
        <template v-slot:alternative-content-1
          >Genau, Mia wendet hier als Ableitungsstrategie das Gegensinnige
          Verändern an. Diese Strategie lässt sich von dem Konstanzgesetz der
          Multiplikation an (a·b = (a:2)·(b·2)) ableiten. Dies bietet sich an,
          da durch das gegensinnige Verändern eine Aufgabe aus dem kleinen
          Einmaleins geschaffen wird. So entsteht eine deutlich einfachere
          Aufgabe.</template
        >
        <template v-slot:alternative-content>
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr>
                  <td>16⋅4 = 8⋅8</td>
                  <td>Gegensinniges Verändern</td>
                  <td>Konstanzgesetz</td>
                </tr>
              </tbody></template
            ></v-simple-table
          >
          <p>
            Mia nutzt bei der Aufgabe 12·5 die Rechenstrategie des gegensinnigen
            Veränderns, indem sie den Multiplikator halbiert und den
            Multiplikanden entsprechend verdoppelt. Aus diesem Grund kann auch
            vermutet werden, dass sie die Aufgabe 16⋅4 auf dem gleichen Weg
            löst. Somit folgt aus der Aufgabe 16⋅4 die Kernaufgabe 8 · 8. Die
            hier verwendete Strategie basiert auf dem Konstanzgesetz: a·b =
            (a:2)·(b·2).
          </p>
          <p>
            Genau, Mia wendet hier als Ableitungsstrategie das Gegensinnige
            Verändern an. Diese Strategie lässt sich von dem Konstanzgesetz der
            Multiplikation an (a·b = (a:2)·(b·2)) ableiten. Dies bietet sich an,
            da durch das gegensinnige Verändern eine Aufgabe aus dem kleinen
            Einmaleins geschaffen wird. So entsteht eine deutlich einfachere
            Aufgabe.
          </p>
        </template>
      </LernbausteinStempeln>
    </div>
    <div style="background-color: white">
      <LernbausteinStempeln
        :id="LBSTStempeln[1].id"
        :workOrder="LBSTStempeln[1].workOrder"
        :image="LBSTStempeln[1].image"
        :audio="LBSTStempeln[1].audio"
        :elements="LBSTStempeln[1].elements"
      >
        <template v-slot:alternative-content-0>
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr>
                  <td>9⋅6=9 ⋅(5+1) = 9⋅5+9⋅1</td>
                  <td>5-mal Aufgaben und 1-mal Aufgaben</td>
                  <td>Zerlegen eines Faktors</td>
                  <td>Distributivgesetz</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <p>
            Mia nutzt bei der Aufgabe 8·7 die Rechenstrategie des Zerlegens
            eines Faktors, indem sie den Multiplikand 7 in 5 und 2 zerlegt und
            anschließend die Aufgabe mithilfe den Kernaufgaben 8⋅5 und 8⋅2 löst.
            Aus diesem Grund kann auch vermutet werden, dass sie die Aufgabe 9⋅6
            auf dem gleichen Weg löst. Vermutlich zerlegt sie hier den
            Multiplikanden in die Kernaufgaben 9·5 und 9⋅1. Die angewendete
            Strategie basiert auf dem Distributivgesetz: a∙b+a∙c = a · (b+c).
          </p>
        </template>
        <template v-slot:alternative-content-1>
          <p>
            Sehr gut, Mia nutzt die Ableitungsstrategie der Zerlegung eines
            Faktors, damit sie anschließend auf ihr gefestigtes Vorwissen zu den
            5-mal und 1-mal Aufgaben, die zu den Kernaufgaben im kleinen
            Einmaleins gehören, zurückgreifen kann. Die verwendete Strategie
            lässt sich von dem Distributivgesetz der Multiplikation (a∙b+a∙c = a
            (b+c)) ableiten.
          </p>
        </template>
        <template v-slot:alternative-content>
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr>
                  <td>9⋅6=9 ⋅(5+1) = 9⋅5+9⋅1</td>
                  <td>5-mal Aufgaben und 1-mal Aufgaben</td>
                  <td>Zerlegen eines Faktors</td>
                  <td>Distributivgesetz</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <p>
            Mia nutzt bei der Aufgabe 8·7 die Rechenstrategie des Zerlegens
            eines Faktors, indem sie den Multiplikand 7 in 5 und 2 zerlegt und
            anschließend die Aufgabe mithilfe den Kernaufgaben 8⋅5 und 8⋅2 löst.
            Aus diesem Grund kann auch vermutet werden, dass sie die Aufgabe 9⋅6
            auf dem gleichen Weg löst. Vermutlich zerlegt sie hier den
            Multiplikanden in die Kernaufgaben 9·5 und 9⋅1. Die angewendete
            Strategie basiert auf dem Distributivgesetz: a∙b+a∙c = a · (b+c).
          </p>
          <p>
            Sehr gut, Mia nutzt die Ableitungsstrategie der Zerlegung eines
            Faktors, damit sie anschließend auf ihr gefestigtes Vorwissen zu den
            5-mal und 1-mal Aufgaben, die zu den Kernaufgaben im kleinen
            Einmaleins gehören, zurückgreifen kann. Die verwendete Strategie
            lässt sich von dem Distributivgesetz der Multiplikation (a∙b+a∙c = a
            (b+c)) ableiten.
          </p>
        </template>
      </LernbausteinStempeln>
    </div>
    <AppLiteraturEME />
    <AppBottomNavEME
      back="/einmaleins/hintergrundwissen/kernaufgaben"
      next="/einmaleins/hintergrundwissen/aufgaben-und-darstellungen-vernetzen"
    />
  </div>
</template>

<script>
import AppBottomNavEME from "@/common/AppBottomNavEME";
import AppLiteraturEME from "@/common/AppLiteraturEME";
import LernbausteinStempeln from "@/components/faledia/explorativeElemente/LernbausteinStempeln";
import LernbausteinSchieberegler from "@/components/faledia/explorativeElemente/LernbausteinSchieberegler";

import LBST16x4 from "@/components/faledia/seiten/einMalEins/LBST/LBST_Stempeln_16x4";
import LBST9x6 from "@/components/faledia/seiten/einMalEins/LBST/LBST_Stempel_9x6";
import LBST_Schieberegler_Kern from "@/components/faledia/seiten/einMalEins/LBST/LBST_Schieberegler_Kernaufgaben";

export default {
  components: {
    AppBottomNavEME,
    AppLiteraturEME,
    LernbausteinStempeln,
    LernbausteinSchieberegler,
  },
  data: () => ({
    LBSTStempeln: [LBST16x4, LBST9x6],
    Schieberegler: LBST_Schieberegler_Kern,
  }),
};
</script>

<style scoped>
#app .no-wrap {
  white-space: nowrap;
  overflow: auto;
}
</style>
