function createObject() {
  let example = {
    id: "LBST_Stempeln_16x4",
    workOrder:
      "Mia beschreibt im folgenden Audio ihren Rechenweg zu den Aufgaben 12·5 und 4·14.",
      audio: require("@/assets/eme/mia_12mal5.mp4"),
    elements: [
      [
        {
          question: "Wie rechnet Mia vermutlich 16⋅4?",
          answers: [
            {
              correct: true,
              text: "16⋅4 = 8⋅8",
              response: "Sehr gut, genau so hat Mia die Aufgabe gelöst.",
            },
            {
              correct: false,
              text: "15⋅5 = 10⋅5+5⋅5",
              response:
                "Hören Sie sich Mias Beschreibung nochmal an. Sie hat beide Faktoren verändert.",
            },
            {
              correct: false,
              text: "16⋅4 = 16⋅2⋅2 = 32⋅2",
              response:
                "Hören Sie sich Mias Beschreibung nochmal an. ie hat beide Faktoren in einem Schritt verändert.",
            },
          ],
        },
        {
          question:
            "Welche Ableitungsstrategie wendet Mia bei dieser Aufgabe an?",
          answers: [
            {
              correct: true,
              text: "Gegensinniges Verändern",
              response:
                "Genau, Mia wendet hier die Ableitungsstrategie des gegensinnigen Veränderns an, indem sie den Multiplikator halbiert und den Multiplikanden entsprechend verdoppelt.",
            },
            {
              correct: false,
              text: "Nachbaraufgaben (Multiplikand bleibt gleich)",
              response:
                "Hören Sie sich nochmal an, wie Mia vorgeht. Zur Erinnerung bei Nachbaraufgaben, wo der Multiplikand gleich bleibt, werden Nachbaraufgaben aus der um 1 erhöhten oder verminderten Reihe zur Ableitung genommen. Beispiel: 6⋅5 sind ein Fünfer mehr, als 5⋅5 aus 5 Fünfer werden 6 Fünfer.",
            },
            {
              correct: false,
              text: "Tauschaufgabe",
              response:
                "Hören Sie sich nochmal an, wie Mia vorgeht. Zur Erinnerung bei Tauschaufgaben werden die Faktoren getauscht, da manchmal die Tauschaufgabe einfach zu lösen ist oder eine Ableitung erleichtert. Beispiel: 5⋅3 wird zu 3⋅5.",
            },
            {
              correct: false,
              text: "Zerlegen eines Faktors",
              response:
                "Hören Sie sich nochmal an, wie Mia vorgeht. Zur Erinnerung beim Zerlegen eines Faktors wird einer der beiden Faktoren einer Multiplikationsaufgabe so zerlegt, dass anschließend zwei leichteren Aufgaben entstehen,  mit denen gerechnet werden kann. Beispiel: 4⋅6 = 4⋅ (2+4) = 4⋅2 + 4⋅4.",
            },
          ],
        },
      ],
      [
        {
          question:
            "Auf welchem Rechengesetz beruht die Ableitungsstrategie, die Mia hier nutzt?",
          answers: [
            {
              correct: false,
              text: "Kommutativgesetz",
              response:
                "Hören Sie sich nochmal an, wie Mia vorgeht. Bei welchem Gesetz ist die Veränderung beider Faktoren in einem Schritt zentral? Zur Erinnerung das Kommutativgesetz: a+b = b+a.",
            },
            {
              correct: false,
              text: "Distributivgesetz",
              response:
                "Hören Sie sich nochmal an, wie Mia vorgeht. Bei welchem Gesetz ist die Veränderung beider Faktoren in einem Schritt zentral? Zur Erinnerung das Distributivgesetz: a∙b+a∙c = a (b+c).",
            },
            {
              correct: false,
              text: "Assoziativgesetz",
              response:
                "Hören Sie sich nochmal an, wie Mia vorgeht. Bei welchem Gesetz ist die Veränderung beider Faktoren in einem Schritt zentral? Zur Erinnerung das Assoziativgesetz: (a+b)+c= a+(b+c).",
            },
            {
              correct: true,
              text: "Konstanzgesetz",
              response:
                "Sehr gut, Mia nutzt hier das Konstanzgesetz der Multiplikation: a·b = (a:2)·(b·2).",
            },
          ],
        },
      ],
    ],
  };
  return example;
}

export default createObject();